<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <!--  Modal spinner  -->
    <modal-spinner v-if="ui.loading === true"/>
    <div v-else class="modal-block">
      <div class="modal-header">
        <h3>
          Add new Role
        </h3>
      </div>
      <div class="modal-body">
        <div class="modal-body-container border-0 p-0">
          <span class="title">
            Select Role
          </span>
        </div>
        <div class="row roles-list w-100 m-auto">
          <!--   For inactive role use class inactive   -->
          <div class="col-12 roles-list-item">
            <div class="row">
              <div class="col-8 my-auto">
                <div class="title">
                  Assistant
                </div>
              </div>
              <div class="col-4 role-persons">
                <img alt="Nathan" src="@/assets/icons/Nathan@2x.png">
                <img alt="Nathan" src="@/assets/icons/Nathan@2x.png">
                <img alt="Nathan" src="@/assets/icons/Nathan@2x.png">
                <img alt="Nathan" src="@/assets/icons/Nathan@2x.png">
                <img alt="Nathan" src="@/assets/icons/Nathan@2x.png">
                <span class="persons-total">
                  +10
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-start">
        <button class="btn btn-primary" @click="saveRole">
          <span v-if="ui.saving === true"
                aria-hidden="true"
                class="spinner-border spinner-border-sm text-light" role="status">
          </span>
          <span v-else>
            Save
          </span>
        </button>
        <button class="btn btn-cancel ml-3" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ModalSpinner from "../../../components/modal/ModalSpinner";

export default {
  name: 'AddNewRoleModal',
  components: {ModalSpinner},
  data() {
    return {
      roles: [
        {
          id: 1,
          title: 'AVP',
          isActive: true,
          isChecked: false
        }
      ],
      ui: {
        loading: false,
        saving: false
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;

      setTimeout(() => {
        this.ui.loading = false;
      }, 1500)
    },
    saveRole() {
      this.ui.saving = true;

      setTimeout(() => {
        this.ui.saving = false;
        this.$emit('close');
      }, 1500)
    }
  },
  beforeMount() {
    this.getData()
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-block {
    .modal-body {
      &-container {
        span {
          &.title {
            color: rgba(0, 0, 0, .46);
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 24px;
          }
        }
      }

      .roles-list {
        &-item {
          border-bottom: 1px solid #E7E8E8;
          padding: 20px 0;
          transition: all .3s;
          cursor: pointer;

          &.inactive {
            opacity: 0.2;
          }

          &:hover {
            background: #f1f1f1;
          }

          .title {
            color: #000000;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 21px;
          }

          .role-persons {
            display: flex;
            justify-content: flex-end;

            img {
              height: 32px;
              width: 32px;
              border: 2px solid #fff;
              border-radius: 50%;

              &:not(:first-child) {
                margin-left: -10px;
              }
            }
          }

          .persons-total {
            display: flex;
            height: 32px;
            min-width: 32px;
            box-sizing: border-box;
            padding: 5px;
            border: 2px solid #eee;
            border-radius: 18px;
            background-color: #d8d8d8;
            margin-left: -10px;
            color: #4A4A4A;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 18px;
          }
        }
      }
    }
  }

  .modal-footer {
    .btn {
      &.btn-primary {
        max-width: 110px;
        width: 100%;
      }
    }
  }
}
</style>
