<template>
  <div class="modal modal-small-center box-shadow-light border-small">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header border-0">
        <div>
          <h3>
            Upload Ratesheet
          </h3>
          <span>
            Upload the recent rate sheet in csv format
          </span>
        </div>
      </div>
      <div class="modal-body pt-2 pb-2">
        <file-field :files="files" />
      </div>
      <div class="modal-footer border-0 justify-content-start pb-3">
        <button class="btn bg-green w-50 m-0">
          Upload
        </button>
        <button class="btn btn-cancel" @click="$emit('close')">
          Cancel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import FileField from "../../../components/form/FileField";
export default {
  name: 'UploadRatesheetModal',
  components: { FileField },
  data() {
    return {
      files: [],
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  .modal-header {
    position: initial;
    h3 {
      color: #000000;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 24px;
    }
    span {
      color: rgba(0,0,0,.67);
      font-size: 12px;
      letter-spacing: 0;
      line-height: 18px;
    }
  }
  .modal-footer {
    position: initial;
  }
}
</style>
