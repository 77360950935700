<template>
  <div>
    <div class="page-header d-flex align-items-center">
      <div class="d-flex align-items-center">
        <router-link :to="{ name: 'corr_pricing' }">
          <button class="btn btn-control">
            <img alt="Return back" src="@/assets/icons/bold-icon previous.svg">
          </button>
        </router-link>
        <h3 class="page-header-title ms-2">Corr Pricer Config </h3>
      </div>
    </div>

    <!--  Page spinner  -->
    <div class="text-center mt-4" v-if="ui.loading === true">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>

    <div class="page-content p-normal" v-else>
      <!--   Compensation block  -->
      <div class="config-block comp-block">
        <div class="config-block-header align-items-center">
          <div>
            <span class="title">Compensation</span>
            <span class="subtitle">Compensation/Margin for each lead source</span>
          </div>
          <base-button title="Add Partner" action="primary" size="sm" :disabled="!showAddPartner" @click-btn="openModal(null)" />
        </div>
        <div class="table mt-3">
          <table class="table table-equal">
            <thead>
            <tr>
              <th>Name</th>
              <th class="text-center">Compensation</th>
              <th class="text-center">Min. compensation</th>
              <th class="text-center">Max. compensation</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="partners.length === 0">
              <td colspan="5">
                <div class="modal-alert modal-alert_advice text-center">
                  No Records Found
                </div>
              </td>
            </tr>
            <tr v-for="(partner, index) in partners" :key="index">
              <td>{{ partner.name }}</td>
              <td class="text-center">{{ partner.comp }}%</td>
              <td class="text-center">{{ convertToCurrency(partner.minComp) }}</td>
              <td class="text-center">{{ convertToCurrency(partner.maxComp) }}</td>
              <td>
                <div class="d-flex justify-content-end">
                  <button class="btn btn-control" :disabled="disabledCompAction(partner)" @click="openModal(partner.id)">
                    <img alt="Edit Compensation" src="@/assets/icons/icon-edit-normal.svg">
                  </button>
                  <button class="btn btn-control ms-2" :disabled="disabledCompAction(partner)" @click="deletePartner(partner.id)">
                    <img alt="Delete Compensation" src="@/assets/icons/delete.svg">
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!--   Permissions block   -->
<!--      <div class="config-block permiss-block">-->
<!--        <div class="config-block-header">-->
<!--          <span class="title">Permissions</span>-->
<!--          <span class="subtitle">Set ratesheet permissions for different roles</span>-->
<!--        </div>-->
<!--        <div class="table mt-3">-->
<!--          <table class="table table-equal">-->
<!--            <thead>-->
<!--            <tr>-->
<!--              <th>Role</th>-->
<!--              <th>Comp Visibility</th>-->
<!--              <th>Referral</th>-->
<!--              <th>Bankrate</th>-->
<!--              <th>iRefi</th>-->
<!--              <th>Lendingtree</th>-->
<!--              <th></th>-->
<!--            </tr>-->
<!--            </thead>-->
<!--            <tbody>-->
<!--            <tr>-->
<!--              <td width="400">MLO</td>-->
<!--              <td>-->
<!--                <div class="form-check form-switch">-->
<!--                  <input id="checkVisisble" class="form-check-input" type="checkbox">-->
<!--                  <label class="form-check-label" for="checkVisisble">Visible</label>-->
<!--                </div>-->
<!--              </td>-->
<!--              <td>-->
<!--                <div class="form-check form-switch">-->
<!--                  <input id="checkReferral" class="form-check-input" type="checkbox">-->
<!--                  <label class="form-check-label" for="checkReferral">Enable</label>-->
<!--                </div>-->
<!--              </td>-->
<!--              <td>-->
<!--                <div class="form-check form-switch">-->
<!--                  <input id="checkBankrate" class="form-check-input" type="checkbox">-->
<!--                  <label class="form-check-label" for="checkBankrate">Enable</label>-->
<!--                </div>-->
<!--              </td>-->
<!--              <td>-->
<!--                <div class="form-check form-switch">-->
<!--                  <input id="checkiRefi" class="form-check-input" type="checkbox">-->
<!--                  <label class="form-check-label" for="checkiRefi">Enable</label>-->
<!--                </div>-->
<!--              </td>-->
<!--              <td>-->
<!--                <div class="form-check form-switch">-->
<!--                  <input id="checkLendingtree" class="form-check-input" type="checkbox">-->
<!--                  <label class="form-check-label" for="checkLendingtree">Enable</label>-->
<!--                </div>-->
<!--              </td>-->
<!--              <td>-->
<!--                <button class="btn btn-control">-->
<!--                  <img alt="" src="@/assets/icons/icon-more.svg">-->
<!--                </button>-->
<!--              </td>-->
<!--            </tr>-->
<!--            <tr>-->
<!--              <td width="400">MLO</td>-->
<!--              <td>-->
<!--                <div class="form-check form-switch">-->
<!--                  <input id="checkVisisble1" class="form-check-input" type="checkbox">-->
<!--                  <label class="form-check-label" for="checkVisisble1">Visible</label>-->
<!--                </div>-->
<!--              </td>-->
<!--              <td>-->
<!--                <div class="form-check form-switch">-->
<!--                  <input id="checkReferral1" class="form-check-input" type="checkbox">-->
<!--                  <label class="form-check-label" for="checkReferral1">Enable</label>-->
<!--                </div>-->
<!--              </td>-->
<!--              <td>-->
<!--                <div class="form-check form-switch">-->
<!--                  <input id="checkBankrate1" class="form-check-input" type="checkbox">-->
<!--                  <label class="form-check-label" for="checkBankrate1">Enable</label>-->
<!--                </div>-->
<!--              </td>-->
<!--              <td>-->
<!--                <div class="form-check form-switch">-->
<!--                  <input id="checkiRefi1" class="form-check-input" type="checkbox">-->
<!--                  <label class="form-check-label" for="checkiRefi1">Enable</label>-->
<!--                </div>-->
<!--              </td>-->
<!--              <td>-->
<!--                <div class="form-check form-switch">-->
<!--                  <input id="checkLendingtree1" class="form-check-input" type="checkbox">-->
<!--                  <label class="form-check-label" for="checkLendingtree1">Enable</label>-->
<!--                </div>-->
<!--              </td>-->
<!--              <td>-->
<!--                <button class="btn btn-control"-->
<!--                        name="borrowerAction"-->
<!--                        parent>-->
<!--                  <img alt="Table menu" src="@/assets/icons/icon-more.svg">-->
<!--                </button>-->
<!--                <tippy animateFill="false"-->
<!--                       arrowType="round"-->
<!--                       boundary="window"-->
<!--                       class="table-menu"-->
<!--                       hideOnClick="true"-->
<!--                       interactive="true"-->
<!--                       placement="bottom-end"-->
<!--                       sticky="true" theme="light border" to="borrowerAction"-->
<!--                       trigger="click" zIndex="99">-->
<!--                  <div class="table-menu-block">-->
<!--                    <ul>-->
<!--                      <li class="action-label">-->
<!--                        Actions-->
<!--                      </li>-->
<!--                      <li>-->
<!--                        <img alt="" src="@/assets/icons/icon-delete.svg">-->
<!--                        Delete-->
<!--                      </li>-->
<!--                    </ul>-->
<!--                  </div>-->
<!--                </tippy>-->
<!--              </td>-->
<!--            </tr>-->
<!--            </tbody>-->
<!--          </table>-->
<!--        </div>-->
<!--        <div class="add-new-role" @click="modals.addNewRole = true">-->
<!--          <img alt="Add Role" src="@/assets/icons/Checkbox-Hover-icon-add blue.svg">-->
<!--          <span>-->
<!--            Add New Role-->
<!--          </span>-->
<!--        </div>-->
<!--      </div>-->

<!--      &lt;!&ndash;   Ratesheet block  &ndash;&gt;-->
<!--      <div class="config-block rate-block">-->
<!--        <div class="config-block-header d-flex align-items-center justify-content-between">-->
<!--          <div>-->
<!--            <span class="title">Ratesheet</span>-->
<!--            <div class="subtitle">Last updated Monday, Jan 25, 2021 — 7:45 pm</div>-->
<!--          </div>-->
<!--          <button class="btn btn-outline-dark" @click="modals.uploadRatesheet = true">-->
<!--            <img alt="Upload Ratesheet" src="@/assets/icons/icon-upload.svg">-->
<!--            <span>Upload Manually</span>-->
<!--          </button>-->
<!--        </div>-->
<!--        <div class="table mt-3">-->
<!--          <table class="table">-->
<!--            <thead>-->
<!--            <tr>-->
<!--              <th>Product</th>-->
<!--              <th>Type</th>-->
<!--              <th>Rate</th>-->
<!--              <th>Property Type</th>-->
<!--              <th>Lock</th>-->
<!--              <th>FICO</th>-->
<!--              <th>State</th>-->
<!--              <th>Zip</th>-->
<!--            </tr>-->
<!--            </thead>-->
<!--            <tbody>-->
<!--            <tr>-->
<!--              <td>Conventional 30 yr fixed</td>-->
<!--              <td>Cashout Refi</td>-->
<!--              <td>2.750%</td>-->
<!--              <td>Single Family detached</td>-->
<!--              <td>30 days</td>-->
<!--              <td>650</td>-->
<!--              <td>CA</td>-->
<!--              <td>90011</td>-->
<!--            </tr>-->
<!--            </tbody>-->
<!--          </table>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <!--  Upload Ratesheet modal  -->
    <transition mode="out-in" name="component-fade">
      <upload-ratesheet-modal v-if="modals.uploadRatesheet === true" @close="modals.uploadRatesheet = false"/>
    </transition>

    <!--  Add New Role modal  -->
    <transition mode="out-in" name="component-fade">
      <add-new-role-modal v-if="modals.addNewRole === true" @close="modals.addNewRole = false"/>
    </transition>

    <!--  Compensation modal  -->
    <transition mode="out-in" name="component-fade">
      <compensation-modal v-if="modals.compensationModal === true" :partner-id="partnerId" @close="closeCompModal"/>
    </transition>
  </div>
</template>

<script>
import UploadRatesheetModal from "./modals/UploadRatesheetModal";
import AddNewRoleModal from "./modals/AddNewRoleModal";
import CompensationModal from "./modals/CompensationModal";
import BaseButton from '../../components/BaseButton.vue';

export default {
  name: 'CorrPricerConfig',
  components: {CompensationModal, AddNewRoleModal, UploadRatesheetModal, BaseButton},
  data() {
    return {
      partners: [],
      partnerId: null,
      modals: {
        uploadRatesheet: false,
        addNewRole: false,
        compensationModal: false
      },
      ui: {
        loading: false
      }
    }
  },
  computed: {
    disabledCompAction() {
      return (partner) => {
        if (this.isGranted('ROLE_CEO_ASSISTANT')) {
          return false;
        }

        return !partner.isEditable;
      }
    },
    showAddPartner() {
      if (this.isGranted('ROLE_CEO_ASSISTANT')) {
        return true
      }

      if (!this.isGranted('ROLE_CEO_ASSISTANT') && this.partners.length >= 3) {
        return false;
      }
      return true;
    }
  },
  methods: {
    getData() {
      this.ui.loading = true

      this.$http.get('/api/v1/corr-pricer/get-partners')
        .then((response) => {
          this.partners = response.data.partners
          this.ui.loading = false
        })
        .catch((error) => {
          this.ui.loading = false
          console.log(error)
        })
    },
    openModal(id) {
      this.modals.compensationModal = true
      this.partnerId = id
    },
    closeCompModal(updateList = false) {
      if (updateList) {
        this.getData()
      }

      this.modals.compensationModal = !this.modals.compensationModal
    },
    deletePartner(id) {
      if (confirm("Delete partner?")) {
        this.$http.delete(`/api/v1/corr-pricer/${id}/delete`)
          .then(() => {this.getData()})
      }
    }
  },
  beforeMount() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.config-block {
  margin-bottom: 36px;

  &-header {
    border-bottom: 1px solid #E7E8E8;
    padding: 18px 0;
    display: flex;
    justify-content: space-between;

    .title {
      color: #000000;
      font-size: 18px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 30px;
    }

    .subtitle {
      display: inline-block;
      color: rgba(0, 0, 0, .46);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
      margin-left: 9px;
    }
  }

  &.comp-block {
    table.table {
      tbody {
        tr {
          padding: 0;

          &:nth-child(even) {
            td {
              background: white;
            }
          }

          td {
            height: 50px;

            button {
              margin: 0 0 0 auto;
            }
          }
        }
      }
    }
  }

  &.permiss-block {
    table.table {
      tbody {
        tr {
          padding: 0;

          &:nth-child(even) {
            td {
              background: white;
            }
          }

          td {
            height: 70px;

            button {
              margin: 0 0 0 auto;
            }
          }
        }
      }
    }
  }

  &.rate-block {
    table.table {
      thead {
        tr {
          th {
            &:first-child {
              padding-left: 15px;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            padding-left: 15px;
            &:nth-child(n + 2) {
              padding-left: 0;
            }
          }
        }
      }
    }
  }

  .form-switch {
    flex-direction: column;
    align-items: initial;
    width: fit-content;
    padding: 0;

    input {
      margin: 0;
    }

    label {
      display: block;
      margin: 9px 0 0 0;
      color: #000000;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 12px;
    }
  }

  .btn-outline-dark {
    color: rgba(0, 0, 0, .7);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
    font-weight: normal;

    span {
      display: inline-block;
      margin-left: 9px;
    }
  }

  .add-new-role {
    cursor: pointer;

    &:hover {
      span {
        color: rgba(0, 0, 0, .66);
      }
    }

    span {
      color: rgba(0, 0, 0, .46);
      font-size: 13px;
      letter-spacing: 0;
      line-height: 13px;
      transition: all .3s;
    }
  }

  div.table {
    table.table {
      margin-bottom: 0;

      &.table-equal {
        thead {
          tr {
            th {
              &:first-child {
                padding-left: 15px;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              &:first-child {
                width: 20%;
              }

              &:nth-child(n + 2) {
                width: 10%;
              }
            }
          }
        }
      }
    }
  }
}

</style>
