<template>
  <div @click="$el.querySelector('input[type=file]').click()" @drag.prevent="submitFiles" class="cursor-pointer">
    <div class="scanned-doc position-relative text-center">
      <input type="file" :id="fieldId" class="position-absolute" @change="submitFiles" @drag.prevent="submitFiles" :multiple="multiple">
      <div class="scanned-doc-text" v-if="files.length > 0">
        <span>{{ files.length }}</span> file{{ (files.length > 1) ? '(s)' : '' }} selected
      </div>
      <div class="scanned-doc-text" v-else>
        Drag File here or <span>Browse</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "FileField",
  props: {
    fieldId: String,
    multiple: Boolean,
    files: Array
  },
  methods: {
    submitFiles(e) {
      this.$emit('change', e)
    }
  }
}
</script>

<style scoped>
input {
  top: 0;
  left: -100% !important;
  z-index: -10;
}
</style>
