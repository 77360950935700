<template>
  <div class="modal">
    <div class="modal-bg" @click="$emit('close')"></div>

    <!--  Preload modal spinner  -->
    <modal-spinner v-if="ui.loading === true" />

    <div class="modal-block" v-else>
      <div class="modal-header">
        <h3>Compensation</h3>
      </div>
      <div class="modal-body">
        <div class="modal-title">Compensation/Margin for each lead source</div>
        <div class="modal-body-container">
          <div class="row">
            <div class="col-3">
              <div class="form-group">
                <label for="name">Name</label>
                <input type="text" class="form-control" id="name" placeholder="Bankrate" autocomplete="off"
                       v-model="partner.name" v-input-filled="partner.name">
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label for="comp">Compensation</label>
                <div class="input-with-icon">
                  <span class="icon">%</span>
                  <input type="number" class="form-control" id="comp" placeholder="1.5"
                         v-model="partner.comp" v-input-filled="partner.comp">
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label for="minComp">Min. compensation</label>
                <div class="input-with-icon">
                  <span class="icon">$</span>
                  <input type="number" class="form-control" id="minComp" placeholder="7500"
                         v-model="partner.minComp" v-input-filled="partner.minComp">
                </div>
              </div>
            </div>
            <div class="col-3">
              <div class="form-group">
                <label for="maxComp">Max. compensation</label>
                <div class="input-with-icon">
                  <span class="icon">$</span>
                  <input type="number" class="form-control" id="maxComp" placeholder="9500"
                         v-model="partner.maxComp" v-input-filled="partner.maxComp">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer justify-content-start">
        <base-button title="Save" action="secondary-default" @click-btn="saveData" :loading="ui.saving" />
        <base-button title="Cancel" action="secondary" @click-btn="$emit('close')" class="ms-2" />
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from '../../../components/BaseButton.vue';
import ModalSpinner from "../../../components/modal/ModalSpinner";
export default {
  name: 'CompensationModal',
  components: {ModalSpinner, BaseButton},
  props: {
    partnerId: {type: Number, required: false},
  },
  data() {
    return {
      partner: {
        name: null,
        comp: null,
        minComp: null,
        maxComp: null,
      },
      ui: {
        loading: false,
        saving: false
      }
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;
      this.$http.get(`/api/v1/corr-pricer/${this.partnerId}/show`)
        .then((response) => {
          this.partner = response.data.partner
          this.ui.loading = false;
        })
    },
    saveData() {
      this.ui.saving = true;
      let url = this.partnerId
        ? `/api/v1/corr-pricer/${this.partnerId}/update`
        : `/api/v1/corr-pricer/new-partner`;

      this.$http.post(url, {
        name: this.partner.name,
        comp: this.partner.comp,
        minComp: this.partner.minComp,
        maxComp: this.partner.maxComp,
      }).then(() => {
        this.$emit('close', true)
      }).catch((err) => {
        this.alertError(err.response.data ? err.response.data.message : err.response.statusText);
      }).finally(() => this.ui.saving = false)
    }
  },
  beforeMount() {
    if (this.partnerId) {
      this.getData()
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-block {
  .modal-title {
    color: rgba(0,0,0,.46);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    padding-bottom: 14px;
    border-bottom: 1px solid #E7E8E8;
    margin-bottom: 24px;
  }
  .modal-footer {
    button {
      &.bg-green {
        max-width: 110px!important;
        width: 100%;
      }
    }
  }
}
</style>
